import { Board } from '../../slices/leimaaBoardSlice'

export const generateDynamicManifestData = (board: Board) => {
  const manifestData = {
    name: `Evelia Leimaa - ${board.name}`,
    short_name: 'Evelia Leimaa',
    description: board.name,
    start_url: window.location.href,
    display: 'standalone',
    icons: [
      {
        src: window.location.origin + '/favicon.png',
        sizes: '144x144 64x64 32x32 24x24 16x16',
        type: 'image/png'
      }
    ]
  }
  const stringManifest = JSON.stringify(manifestData)
  const blob = new Blob([stringManifest], { type: 'application/json' })
  const manifestURL = URL.createObjectURL(blob)
  document.querySelector('#web-app-manifest')?.setAttribute('href', manifestURL)
}
