import { useMemo } from 'react'
import { useParams } from 'react-router-dom-v5-compat'

import { useTypedSelector } from '../../reducerTypes'
import { leimaaBoardSelectors } from '../../slices/leimaaBoardSlice'

const useLeimaaBoard = () => {
  const { uuid } = useParams<{ uuid: string }>()
  const board = useTypedSelector(state => leimaaBoardSelectors.selectById(state, uuid!))
  return useMemo(() => ({ board, uuid }), [board, uuid])
}

export default useLeimaaBoard
