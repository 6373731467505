import {
  createEntityAdapter
} from '@reduxjs/toolkit'
import noop from 'lodash/noop'

import { createGenericSlice } from '../helpers/sliceHelpers'
import { EveliaRootState } from '../reducerTypes'

export type Board = {
  id: string
  name: string
  defaultTimeRecordTypeId: number
  timeRecordTypeIds: number[]
}

const leimaaBoardAdapter = createEntityAdapter<Board>({})

const leimaaBoardSlice = createGenericSlice({
  name: 'leimaaBoard',
  entityAdapter: leimaaBoardAdapter,
  reducers: {
    reFetchRequest: noop
  }
})

export const leimaaBoardSelectors = leimaaBoardAdapter.getSelectors<EveliaRootState>(state => state.leimaaBoards)
export const leimaaBoardActions = leimaaBoardSlice.actions

export default leimaaBoardSlice
