import { emptyArray, timeRecordAccumulationTypes, timeRecordSystemTypes } from '@evelia/common/constants'
import { sortByProperty } from '@evelia/common/helpers'
import { EmployeeModel } from '@evelia/common/types'
import {
  TimeRecordAccumulationModel,
  TimeRecordAccumulationStatModel,
  TimeRecordModel,
  TimeRecordSiteModel,
  TimeRecordTypeModel
} from '@evelia/common/types/timeRecordTypes'
import sortBy from 'lodash/sortBy'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'
import { ValueOf } from 'type-fest'

import { createArgumentSelector, getFindItemByIdSelector, getFindItemsByIdsSelector } from '../helpers/typedSelectorHelpers'
import { EveliaRootState } from '../reducerTypes'
import { findCurrentEmployee, findEmployeeWithId } from './employeeSelectors'

const getTimeRecordsFromArgument = (arg: EveliaRootState | TimeRecordModel[]) => Array.isArray(arg) ? arg : arg.timeRecords.records ?? emptyArray
const getTimeRecordTypesFromArgument = (arg: EveliaRootState | TimeRecordTypeModel[]) => Array.isArray(arg) ? arg : arg.timeRecords.timeRecordTypes.records ?? emptyArray
export const getTimeRecordSitesFromArgument = (arg: EveliaRootState | TimeRecordSiteModel[]) => Array.isArray(arg) ? arg : arg.timeRecords.timeRecordSites.records ?? emptyArray
const getTimeRecordAccumulationsFromArgument = (arg: EveliaRootState | TimeRecordAccumulationModel[]) => Array.isArray(arg) ? arg : arg.timeRecords.timeRecordAccumulations.records ?? emptyArray
const getTimeRecordAccumulationStatsFromArgument = (state: EveliaRootState) => state.timeRecords.timeRecordAccumulations.stats.records

const sortTimeRecords = (timeRecords: TimeRecordModel[]) => sortBy(timeRecords, ['startedAt', 'id'])
const sortTimeRecordTypes = (timeRecordTypes: TimeRecordTypeModel[]) => sortBy(timeRecordTypes, ['systemType', 'id'])

const findCurrentTimeRecordOfEmployee = createCachedSelector(
  getTimeRecordsFromArgument,
  (state, { employeeId }: { employeeId?: number } = {}) => Number(employeeId),
  (timeRecords, employeeId) => timeRecords.find(timeRecord => timeRecord.employeeId === employeeId && timeRecord.endedAt == null)
)((state, { employeeId } = {}) => `${employeeId}`)

const findCurrentTimeRecordOfCurrentEmployee = createSelector(
  getTimeRecordsFromArgument,
  findCurrentEmployee,
  (timeRecords, employee) => timeRecords.find(timeRecord => timeRecord.employeeId === employee.id && timeRecord.endedAt == null)
)

export const getTimeRecords = createSelector(
  getTimeRecordsFromArgument,
  timeRecords => sortTimeRecords(timeRecords)
)

export const getTimeRecordTypes = createSelector(
  getTimeRecordTypesFromArgument,
  timeRecordTypes => sortTimeRecordTypes(timeRecordTypes.filter(timeRecordType => !timeRecordType.isSystem && !timeRecordType.isDeleted))
)

export const getSelectableTimeRecordTypes = createSelector(
  getTimeRecordTypes,
  timeRecordTypes => timeRecordTypes.filter(timeRecordType => timeRecordType.systemType !== timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_AWAY)
)

const findTimeRecordTypesWithIdList = getFindItemsByIdsSelector(getTimeRecordTypesFromArgument)

export const getSelectableTimeRecordTypesByIds = createCachedSelector(
  findTimeRecordTypesWithIdList,
  timeRecordTypes => getTimeRecordTypes(timeRecordTypes).filter(timeRecordType => timeRecordType.systemType !== timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_AWAY)
)((state, timeRecordTypeIds) => JSON.stringify(timeRecordTypeIds))

const filterTimeRecordsByEmployee = (timeRecordTypes: TimeRecordTypeModel[], employee?: EmployeeModel) => {
  if(!employee) {
    return emptyArray
  }
  const employeeRoleIdsOfEmployee = employee.employeeRoleIds
  return timeRecordTypes.filter(timeRecordType =>
    (employeeRoleIdsOfEmployee.some(value => timeRecordType.employeeRoleIds.includes(value)) || !timeRecordType.employeeRoleIds.length)
  )
}

export const getTimeRecordTypesOfCurrentEmployee = createSelector(
  getTimeRecordTypes,
  findCurrentEmployee,
  filterTimeRecordsByEmployee
)

export const getTimeRecordTypesOfEmployee = createCachedSelector(
  getTimeRecordTypes,
  findEmployeeWithId,
  filterTimeRecordsByEmployee
)((state, employeeId) => `${employeeId}`)

export const getSystemTimeRecordTypes = createSelector(
  getTimeRecordTypesFromArgument,
  timeRecordTypes => sortTimeRecordTypes(timeRecordTypes.filter(timeRecordType => timeRecordType.isSystem))
)

export const findTimeRecordTypeWithId = getFindItemByIdSelector(getTimeRecordTypesFromArgument)

export const findCurrentTimeRecordType = createSelector(
  findCurrentTimeRecordOfCurrentEmployee,
  getTimeRecordTypesFromArgument,
  (currentTimeRecord, timeRecordTypes) => currentTimeRecord ? findTimeRecordTypeWithId(timeRecordTypes, currentTimeRecord.timeRecordTypeId) : null
)

export const findCurrentTimeRecordTypeOfEmployee = createCachedSelector(
  findCurrentTimeRecordOfEmployee,
  getTimeRecordTypesFromArgument,
  (currentTimeRecord, timeRecordTypes) => currentTimeRecord ? findTimeRecordTypeWithId(timeRecordTypes, currentTimeRecord.timeRecordTypeId) : null
)((state, { employeeId }) => `${employeeId}`)

const sortByDate = sortByProperty('date', true)

interface TimeRecordAccumulationFilters {
  startDate?: string
  endDate?: string
  types?: ValueOf<typeof timeRecordAccumulationTypes>[]
  employeeId?: number
}

export const getTimeRecordAccumulations = createArgumentSelector(
  [
    getTimeRecordAccumulationsFromArgument,
    (state, { startDate }: TimeRecordAccumulationFilters = {}) => startDate,
    (state, { endDate }: TimeRecordAccumulationFilters = {}) => endDate,
    (state, { types }: TimeRecordAccumulationFilters = {}) => types,
    (state, { employeeId }: TimeRecordAccumulationFilters = {}) => employeeId
  ],
  (timeRecordAccumulations, startDate, endDate, types, employeeId) => {
    const sortedTimeRecordAccumulations = timeRecordAccumulations.sort(sortByDate)
    return sortedTimeRecordAccumulations.filter(timeRecordAccumulation => (
      (startDate == null || startDate <= timeRecordAccumulation.date) &&
      (endDate == null || endDate >= timeRecordAccumulation.date) &&
      (types == null || types.includes(timeRecordAccumulation.type)) &&
      (employeeId == null || employeeId === timeRecordAccumulation.employeeId)
    ))
  }
)

export const calculateTimeRecordAccumulationStats = createArgumentSelector(
  [
    getTimeRecordAccumulations
  ],
  timeRecordAccumulations => {
    const initialValue: Omit<TimeRecordAccumulationStatModel, 'id' | 'updatedAt'> = {
      [timeRecordAccumulationTypes.TIME_RECORD_ACCUMULATION_TYPE_FLEXITIME]: 0,
      [timeRecordAccumulationTypes.TIME_RECORD_ACCUMULATION_TYPE_OVERTIME]: 0,
      [timeRecordAccumulationTypes.TIME_RECORD_ACCUMULATION_TYPE_VACATION_EARNING]: 0,
      [timeRecordAccumulationTypes.TIME_RECORD_ACCUMULATION_TYPE_VACATION_USAGE]: 0,
      [timeRecordAccumulationTypes.TIME_RECORD_ACCUMULATION_TYPE_EXTRA_LEAVE_EARNING]: 0,
      [timeRecordAccumulationTypes.TIME_RECORD_ACCUMULATION_TYPE_EXTRA_LEAVE_USAGE]: 0
    }
    return timeRecordAccumulations.reduce((acc, timeRecordAccumulation) => {
      acc[timeRecordAccumulation.type] += timeRecordAccumulation.value
      return acc
    }, initialValue)
  }
)

export const getTimeRecordAccumulationStats = createCachedSelector(
  getTimeRecordAccumulationStatsFromArgument,
  (state: EveliaRootState, opts: { employeeId?: number }) => opts?.employeeId ?? findCurrentEmployee(state)?.id,
  (timeRecordAccumulationStats, employeeId) => timeRecordAccumulationStats[employeeId]
)((state, { employeeId }) => `${employeeId}`)

export const getTimeRecordLunchTypes = createSelector(
  getTimeRecordTypes,
  timeRecordTypes => timeRecordTypes.filter(timeRecordType => timeRecordType.systemType === timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_LUNCH)
)

const findExtraTimeByDateAndType = (timeRecordAccumulations: TimeRecordAccumulationModel[], date: string, type: ValueOf<typeof timeRecordAccumulationTypes>) => timeRecordAccumulations.find(extraTime => extraTime.date === date && extraTime.type === type)?.value || 0

export const getTimeRecordAccumulationsByDate = createSelector(
  [
    getTimeRecordAccumulations,
    (state, date: string) => date
  ],
  (timeRecordAccumulations, date) => {
    return {
      date,
      flexitime: findExtraTimeByDateAndType(timeRecordAccumulations, date, timeRecordAccumulationTypes.TIME_RECORD_ACCUMULATION_TYPE_FLEXITIME),
      overtime: findExtraTimeByDateAndType(timeRecordAccumulations, date, timeRecordAccumulationTypes.TIME_RECORD_ACCUMULATION_TYPE_OVERTIME)
    }
  }
)
